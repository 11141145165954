import {SizeOption} from "./kuih"

export class Capacity{
    productionDate : string
    orderByDate : string
    totalCapacity : Map<String, number>

    constructor(productionDate : string, orderByDate : string, totalCapacity : Map<String, number>){
        this.productionDate = productionDate
        this.orderByDate = orderByDate
        this.totalCapacity = totalCapacity
    }
    hasCapacity(kuihId : string, options : Array<SizeOption>) : boolean {
        if (this.totalCapacity.has(kuihId)){
            const result = options.find(element => element.quantity <= (this.totalCapacity.get(kuihId) || 0))
            if(result){
                return true
            }
            }
        return false
    }
}

export default Capacity;