import Container from 'react-bootstrap/Container'
import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
    faFacebook,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export interface IContactMessage {
    name: string
    email: string
    message: string
}

interface Props {
    onContactUs: (message: IContactMessage) => void
    maxMessageLength? : number
}


export const ContactUs: React.FC<Props> = (props) => {
    return <div className="contact"><Container>
        <h1 className="title">Message Us</h1>
        <Row xs={1} md={2}>
            <Col>
                <div>
                    
                    <p>Use the form to contact us and we will get back to you as soon as possible.
                        or you can email us <a href={`mailto:the2yings@gmail.com`}>the2yings@gmail.com</a>
                    </p>
                </div>
            </Col>
            <Col>
                <div className="form">
                    <div>
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" placeholder="Name" />
                    </div>
                    <div>
                        <label htmlFor="name">Email</label>
                        <input type="email" id="email" name="email" placeholder="Email" />
                    </div>
                    <div>
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" maxLength={props.maxMessageLength || 500}placeholder="Send us a message and we will get back to you shortly" />
                    </div>
                    <Button>Send</Button>
                </div>
            </Col>
        </Row>

    </Container>
    </div>
}

export default ContactUs