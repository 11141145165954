import React from 'react'
import Container from 'react-bootstrap/Container'
import Accordion from 'react-bootstrap/Accordion'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

interface Props {

}

interface IAQ {
    id: string,
    question: string,
    answer: string
}

const faqs: Array<IAQ> = [
    {
        id: "1",
        question: "The 2 Yings aren't devliverying when i want to order. Can i get a special order?",
        answer: "We understand that speicaly events can happen at times when we maynot have a scheduled Kuih production. If you are looking for kuih when we not schudled to make "
    },
    {
        id: "2",
        question: "Do The 2 Yings use artifuly stuff",
        answer: "No, our kuihs are made fresh"
    },
    {
        id: "3",
        question: "How are your kuihs made",
        answer: "Our kuihs are made fresh on the day"
    },
    {
        id: "4",
        question: "Where are you based?",
        answer: "We are a Council approved home business based in Yarravile"
    }
]

export const FAQ: React.FC<Props> = (props) => {
    return <div className='faq'><Container>
        <Row>
            <h1 className="title">FAQ</h1>
            <Col>
                <div>

                    <Accordion defaultActiveKey="0">
                        {
                            faqs.map((item, index) => {
                                return <Accordion.Item eventKey={index.toString()}>
                                    <Accordion.Header>{item.question}</Accordion.Header>
                                    <Accordion.Body>
                                        {item.answer}
                                    </Accordion.Body>
                                </Accordion.Item>
                            })
                        }
                    </Accordion>
                </div>
            </Col>
        </Row>
    </Container>
    </div>

}

export default FAQ