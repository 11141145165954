import React from 'react';
import Kuih from '../models/kuih'
import Card from 'react-bootstrap/Card'
import log from './assorted-kuihs-words.jpg'
import { VoidExpression } from 'typescript';




export interface Props{
    kuih : Kuih
    isOrderable : boolean
    onClick : (() => void)
}

export const KuihCard : React.FC<Props> = ({kuih, isOrderable, onClick}) => {

    const cannotOrder = () => {

    }


    const className = isOrderable ? "kuih-card" : "kuih-card disabled"

    const clickAction = isOrderable ? onClick : cannotOrder
    console.log(kuih.imageKey)

    return <Card data-testid={`${kuih.id}-card`} className={className}>
        <Card.Img data-testid={`${kuih.id}-image`} variant="top" onClick={clickAction} src={kuih.imageKey}/>
        <Card.Body>
      <Card.Title data-testid={`${kuih.id}-title`} >{kuih.name}</Card.Title>
      <Card.Text>
          <span data-testid={`${kuih.id}-description`} >{kuih.description}</span>
          </Card.Text>
    </Card.Body>

    </Card>
}

export default KuihCard

