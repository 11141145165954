import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './header/header'
import Menu from './menu/menu'
import Footer from './footer/footer'
import { DataService } from './utils/data-centre'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import SocialMedia from './SocialMedia'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";

export interface NavOption {
    name: string, 
    onClick: () => void
}

export interface Props {
    options: Array<NavOption>
}



export const Navigation: React.FC<Props> = ({options}) => {

    return <Navbar expand="md" bg="light" fixed="top">
        <Container>
            <Navbar.Brand>The 2 Yings</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                {
                    options.map(item => {
                        return <Nav className="me-auto">
                                    <Nav.Link onClick={item.onClick}>{item.name}</Nav.Link>
                                </Nav>
                    })
                }
                <SocialMedia/>
            </Navbar.Collapse>
        </Container>
    </Navbar>

}

export default Navigation

