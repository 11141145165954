import Kuih from "../models/kuih";
import Capacity from "../models/capacity";
import { OrderSubmissionResponse } from "../models/order-resposne"; 
import Order from "../models/order"; 
import { rejects } from "assert";
import {dateToString} from './helper'

export interface DataService {

    getKuih(): Promise<Array<Kuih>>

    getNextProductionCapacity(date : Date) : Promise<Array<Capacity>>

    submitOrder(order : Order) : Promise<OrderSubmissionResponse>

    confirmOrder(order : Order) : Promise<OrderSubmissionResponse>

}

export class DataRepo implements DataService{

    private url : string;

    constructor(url : string){
        this.url = url
    }

    getKuih(): Promise<Array<Kuih>>{
        return fetch(`${this.url}/kuihs`)
        .then(reponse => {
            if(reponse.status == 200){
                return reponse.json()
            } else {
                return Promise.reject(new Error(`${reponse.status} was returned`))
            }
        })
        .then(json => {
            return json as Array<Kuih>
        })
    }
    

    getNextProductionCapacity(date : Date) : Promise<Array<Capacity>>{
        const  dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        return fetch(`${this.url}/capacity?date=${dateToString(date)}`)
        .then(reponse => {
            if(reponse.status == 200){
                return reponse.json()
            } else {
                return Promise.reject(new Error(`${reponse.status} was returned`))
            }
        })
        .then(json => {
            return (json as Array<Capacity>).map(item =>{
                return new Capacity(item.productionDate, item.orderByDate, new Map(Object.entries(item.totalCapacity)))
            })
        })

    }

    submitOrder(order : Order) : Promise<OrderSubmissionResponse>{
        return fetch(`${this.url}/orders`,{
        method: 'POST',
        body: JSON.stringify(order)
        })
        .then(reponse => {
            if(reponse.status == 201){
                return reponse.json()
            } else {
                return Promise.reject(new Error(`${reponse.status} was returned`))
            }
        })
        .then(json => {
            return json as OrderSubmissionResponse
        })
    }

     confirmOrder(order : Order) : Promise<OrderSubmissionResponse>{
        return fetch(`${this.url}/orders`,{
            method: 'PUT',
            body: JSON.stringify(order)
            })
            .then(reponse => {
                if(reponse.status == 200){
                    return reponse.json()
                } else {
                    return Promise.reject(new Error(`${reponse.status} was returned`))
                }
            })
            .then(json => {
                return json as OrderSubmissionResponse
            })

    }

}