import React from 'react';
import './header.css';
import SocialMedia from '../SocialMedia'
import Image from 'react-bootstrap/Image';

const facebookLogo = "/facebook-logo.png"
const instantgram = "instantgram-logo.jpg"

export const Header : React.FC<{}> = () => {

    return <div className="welcome" >
        <div className-="text">
        <h1 >The Two Yings</h1>
        <h2>Home made kuih</h2>
        <h3>DEMO</h3>
        </div>
    </div>
}

export default Header;