import React, { useState } from 'react'
import {Kuih, SizeOption} from '../models/kuih'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { centsToDollars } from '../utils/helper'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import OrderItem from '../models/order-item'


export interface OrderEvent {
    successfulOrder: boolean
    item? : OrderItem
}


export interface Props {
    kuih?: Kuih
    callback: ((o: OrderEvent) => void)
}

interface DisplayState {
    numberOrdered : number
    optionSelected: number
}

const initState = {
    numberOrdered: 1,
    optionSelected: 0
}

export const KuihDisplay: React.FC<Props> = (props) => {

    const [quantity, setQuantity] = useState(initState);

    const show = props.kuih != undefined

    const reset = () => {
        setQuantity(initState)
    }

    const backOnClick = () => {
        reset()
        props.callback({
            successfulOrder: false
        })
        
    }

    const orderOnClick = () => {
        if (props.kuih) {
            props.callback({
                successfulOrder: true,
                item: {
                    kuih: props.kuih,
                    sizeOption: props.kuih.options[quantity.optionSelected],
                    quality: quantity.numberOrdered
                }
            })
        }
        reset()
    }

    const updateQuantity = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.value) {
            const q: number = + evt.target.value
            setQuantity({
                numberOrdered: q,
                optionSelected: quantity.optionSelected
            })
        }

    }

    const updateSizeOption = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        if (evt.target.value) {
            const index: number = + evt.target.value
            setQuantity({
                numberOrdered: quantity.numberOrdered,
                optionSelected: index
            })
        }

    }

    return <Modal
            className="kuih-display"
            show={show}
            onHide={backOnClick}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            data-testid="kuih-display">

            <Modal.Header>
                <Modal.Title data-testid="title">{props.kuih?.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <img data-testid="image" className="boxImage" src={props.kuih?.imageKey}></img>
                        </Col>
                        <Col>
                            <div>
                                <p data-testid="description" >{props.kuih?.description}</p>
                                <label>Size</label>
                                <select data-testid="size" className="form-select" aria-label="Default select example" onChange={updateSizeOption} >
                                    {
                                        props.kuih?.options.map((item, index) => {
                                            return <option  key={index} value={index}>${centsToDollars(item.costInCents)} for {item.quantity}</option>
                                        })
                                    }
                                </select>
                                <label>Quantity</label>
                                <input data-testid="quantity" type='number' defaultValue={1} id='quantity' min="1" onChange={updateQuantity}></input>
                                <div data-testid="total" className='total'>
                                    Total: ${centsToDollars((props.kuih?.options[quantity.optionSelected].costInCents || 0) * quantity.numberOrdered)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

            <Modal.Footer>
                <Button data-testid="back" onClick={backOnClick}>Back</Button>
                <Button data-testid="order" onClick={orderOnClick}>Order</Button>
            </Modal.Footer>
        </Modal>


}

export default KuihDisplay;