import React from 'react';
import {
  faFacebook,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SocialMedia: React.FC<{}> = () => {

  return <div className="social-media">
    <a href="https://www.facebook.com/The2Yings/"
      className="facebook social">
      <FontAwesomeIcon icon={faFacebook} size="2x" />
    </a>
    <a href="https://www.instagram.com/the2yings/?hl=en"
      className="instagram social">
      <FontAwesomeIcon icon={faInstagram} size="2x" />
    </a>
  </div>
}

export default SocialMedia;