import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './header/header'
import Menu from './menu/menu'
import Footer from './footer/footer'
import {DataService} from './utils/data-centre'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Navigation from './Navigation'
import FAQ from './faq/faq'
import {ContactUs, IContactMessage} from './contact/contact-us'
import { Col, Row } from 'react-bootstrap';
import Basket from './checkout/Basket';
import OrderItem from './models/order-item';

export interface Props {
    dataService: DataService
}



export const MainPage: React.FC<Props> = (props) => {
    const menu = React.useRef<HTMLDivElement>(null)
    const faq = React.useRef<HTMLDivElement>(null)
    const contactUs = React.useRef<HTMLDivElement>(null)
    return <div className="main">
            <Navigation
            options={[
                {
                    name: "Menu",
                    onClick:() => { if(menu.current){menu.current?.scrollIntoView() }}
                },
                {
                    name: "FAQ",
                    onClick:() => { if(faq.current){faq.current?.scrollIntoView() }}
                },
                {
                    name: "Contact Us",
                    onClick:() => { if(contactUs.current){contactUs.current?.scrollIntoView() }}
                }

            ]} ></Navigation>
            <Header></Header>
            <div ref={menu}>
            <Menu dataService={props.dataService}></Menu>
            </div>
            <div ref={faq}>
            <FAQ></FAQ>
            </div>
            <div ref={contactUs}>
                <ContactUs onContactUs={(message: IContactMessage) => message}></ContactUs>
            
            </div>
            <Footer/>
    </div>

}

export default MainPage