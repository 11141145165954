import React, { useState, useEffect } from 'react';
import './menu.css';
import Kuih from '../models/kuih'
import OrderItem from '../models/order-item'
import KuihCard from './kuih-card'
import CardGroup from 'react-bootstrap/CardGroup'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Row'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { KuihDisplay, OrderEvent } from './kuih-display'
import { DataService } from '../utils/data-centre'
import Capacity from '../models/capacity'



export interface IProps {
    dataService: DataService
}


interface State {
    kuihs: Array<Kuih>
    basket: Map<string, OrderItem>
    capacity?: Capacity
    selectedKuih?: Kuih
}

const initial = {
    kuihs: Array<Kuih>(),
    basket: new Map<string, OrderItem>()
}

export const Menu: React.FC<IProps> = (props) => {

    const [state, setKuih] = useState<State>(initial);

    const kuihOnClick = (kuih: Kuih) => {
        setKuih({
            kuihs: state.kuihs,
            basket: state.basket,
            selectedKuih: kuih,
            capacity: state.capacity
        })

    }

    const orderCallback = (event: OrderEvent) => {
        if (event.successfulOrder && event.item) {
            state.basket.set(event.item.kuih.id, event.item)


        }

        setKuih({
            kuihs: state.kuihs,
            basket: state.basket,
            capacity: state.capacity

        })

    }




    useEffect(() => {

        Promise.all<Array<Kuih>, Array<Capacity>>(
            [props.dataService.getKuih(),
            props.dataService.getNextProductionCapacity(new Date())]
        ).then(result =>
            setKuih({
                kuihs: result[0],
                basket: state.basket,
                capacity: result[1][0]

            })
        )
            .catch(error => {
                console.log(error)
                console.log('Failed to get data from API')
            })



    }, []);

    return <div className="menuContainer">
        

        <Container>
            <h1 className="title">Menu</h1>
            <Row xs={1} md={3} className="justify-content-md-center">
                {
                    state.kuihs.map((box, i) =>
                        <Col data-testid={`kuih-${box.id}`} key={box.id}>
                            <KuihCard  kuih={box} isOrderable={(state.capacity?.hasCapacity(box.id, box.options) || false)} onClick={() => kuihOnClick(box)} />
                        </Col>
                    )
                }
            </Row>
        </Container>

        <KuihDisplay kuih={state.selectedKuih} callback={orderCallback}></KuihDisplay>

    </div>
}

export default Menu;