import React from 'react';
import SocialMedia from '../SocialMedia'

export const Footer: React.FC<{}> = () => {

    return <div className="footer">
        <SocialMedia />
        <div>
            <div> The 2 Yings</div>
            <div> the2yings@gmail.com</div>
            <div> Copyright {new Date().getFullYear()}</div>
        </div>
    </div>
}

export default Footer;