import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './header/header'
import Menu from './menu/menu'
import Footer from './footer/footer'
import { DataRepo } from './utils/data-centre'
import MainPage from './main-page';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  const invoke = "https://t070e75xmh.execute-api.ap-southeast-2.amazonaws.com/service"
  const dataRepo = new DataRepo(invoke)

  return <Router>
    <Switch>
      <Route path="/basket">
      </Route>
      <Route path="/">
        <MainPage dataService={dataRepo}></MainPage>
      </Route>
    </Switch>
  </Router>
}


export default App;
